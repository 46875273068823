import React from 'react'
import './ContactUs.sass'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Map from '../../ui/Map/Map'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsPopUp from './ContactUsPopUp'

function ContactUs() {
  return (
    <section id="contacts" className="ContactUs DefaultSection">
      <ContactUsPopUp />
      <SectionTitle title="Контакты" />
      <div className="ContactUs-Container">
        <div className="ContactUs-Info">
          <div className="ContactUs-Links">
            <ContactUsInfoTile
              icon="mobile"
              data={<a href="tel:+7 (921) 156-23-64">+7 (921) 156-23-64</a>}
              description="Viber, Telegram, Whatsapp"
            />
            <ContactUsInfoTile icon="map-marker-alt" data="Весь мир" />
            <ContactUsInfoTile icon="envelope" data="info@oneday.tv" />
            <ContactUsInfoTile
              icon="link"
              data={
                <div className="SocialBlock">
                  <a
                    href="https://www.instagram.com/oneday__tv/"
                    target="blank"
                  >
                    <Icon name="instagram" weight="brand" />
                  </a>
                  <a href="https://vk.com/tvoneday" target="blank">
                    <Icon name="vk" weight="brand" />
                  </a>
                </div>
              }
            />
          </div>
          <div className="ContactUs-Map">
            <h5>Где мы уже побывали?</h5>
            <p>
              Мы готовы путешествовать по всему миру, что бы запечатлить все
              Ваши моменты
            </p>
            <Map />
          </div>
        </div>
        <ContactUsForm />
      </div>
    </section>
  )
}

function ContactUsInfoTile({ icon, data, description }) {
  return (
    <div className="ContactUsInfoTile">
      <div className="ContactUsInfoTile-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <div className="ContactUsInfoTile-Data">
        <p className="Data">{data}</p>
        {description && <p className="Description">{description}</p>}
      </div>
    </div>
  )
}
export default ContactUs
