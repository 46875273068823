import React from 'react'
import { Helmet } from 'react-helmet'
import './PrivacyPolicy.sass'

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <Helmet>
        <title>Студия One Day — политика обработки персональных данных</title>
      </Helmet>
      <h4>Политика обработки персональных данных</h4>
      <p>
        Предоставляя свои персональные данные Пользователь даёт согласие на
        обработку, хранение и использование своих персональных данных на
        основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в
        следующих целях:
      </p>

      <ul>
        <li>Осуществление клиентской поддержки;</li>
        <li>Получения Пользователем информации о маркетинговых событиях;</li>
        <li>
          Проведения аудита и прочих внутренних исследований с целью повышения
          качества предоставляемых услуг.
        </li>
      </ul>

      <p>
        Под персональными данными подразумевается любая информация личного
        характера, позволяющая установить личность Пользователя/Покупателя такая
        как:
      </p>

      <ul>
        <li>Фамилия, Имя, Отчество;</li>
        <li>Дата рождения;</li>
        <li>Контактный телефон;</li>
        <li>Адрес электронной почты.</li>
      </ul>

      <p>
        Персональные данные Пользователей хранятся исключительно на электронных
        носителях и обрабатываются с использованием автоматизированных систем,
        за исключением случаев, когда неавтоматизированная обработка
        персональных данных необходима в связи с исполнением требований
        законодательства.
      </p>

      <p>
        Компания обязуется не передавать полученные персональные данные третьим
        лицам, за исключением следующих случаев: По запросам уполномоченных
        органов государственной власти РФ только по основаниям и в порядке,
        установленным законодательством РФ.
      </p>

      <p>
        Компания оставляет за собой право вносить изменения в одностороннем
        порядке в настоящие правила, при условии, что изменения не противоречат
        действующему законодательству РФ. Изменения условий настоящих правил
        вступают в силу после их публикации на Сайте.
      </p>
    </div>
  )
}

export default PrivacyPolicy
