import React from 'react'
import CheckBlock from '../../ui/CheckBlock/CheckBlock'
import Button from '../../ui/Button/Button'
import vp1 from './assets/vp1.jpg'
import vp2 from './assets/vp2.jpg'

function VideoPhoto({ setShowContactUs }) {
  return (
    <div
      id="service-video-and-photo"
      className="Services-Container Services-VideoAndPhoto"
    >
      <div className="Photo Photo1">
        <div
          className="Photo_bordered"
          style={{
            backgroundImage: `url(${vp1})`,
            width: '320px',
            height: '480px',
          }}
        />
      </div>
      <div className="InfoBlock">
        <h5>Видеосъемка + фотосъемка</h5>
        <p>
          Мы команда One Day, фотографы, видеограф и флорист Ваших самых важных
          событий. Мы сёстры и лучшие друзья, что все вместе это образует для
          Вас ряд особых преимуществ:
        </p>
        <CheckBlock text="Мы знаем, как работать вместе! Мы не мешаем друг другу, не попадаем в кадр, эффективно используем время и снимаем одновременно, часто понимаем друг друга без слов." />
        <CheckBlock text="Вы проживаете свой день максимально естественно, не позируя по несколько раз одни и те же сцены в разные камеры, мы всё ловим сразу и с лучшего ракурса!" />
        <CheckBlock text="Материальный бонус: заказывая у нас комплекс услуг, Вы дополнительно выигрываете в стоимости. Потому что мы даём хорошие скидки и бонусы на работу команды" />
        <div className="InfoBlock-Buttons">
          <Button
            title="Узнать подробнее"
            theme="solid"
            fill="gray"
            icon="long-arrow-right"
            iconPosition="right"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Видеосъемка + фотосъемка',
                emailSubject: 'Запрос пакета услуг [Видео + Фото]',
              })
            }
          />
        </div>
      </div>
      <div className="Photo Photo2" style={{ alignSelf: 'end' }}>
        <div
          className="Photo_layered"
          style={{
            backgroundImage: `url(${vp2})`,
            width: '280px',
            height: '420px',
          }}
        />
      </div>
    </div>
  )
}

export default VideoPhoto
