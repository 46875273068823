import React from 'react'
import Button from '../../ui/Button/Button'
import v1 from './assets/v1.jpg'
import v2 from './assets/v2.jpg'

function Video({ setShowContactUs }) {
  return (
    <div
      id="service-video"
      className="Services-Container"
      style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
    >
      <div className="InfoBlock">
        <h5>Видеосъемка</h5>
        <p>
          Такой праздник как свадьба всегда полон эмоций: волнения, радости и
          романтики. Это грандиозное событие для молодой пары. Само собой
          разумеется, что о таком мероприятии должна остаться хорошая память,
          память на всю жизнь. Видеосъемка очень важна, а видеограф поможет Вам
          передать то, что не способна передать фотография. Свадебное видео
          является идеальным для приятной ностальгии спустя многие годы.
        </p>
        <p>
          Для свадебной съёмки нам близок стиль «художественный репортаж». Мы
          стараемся не вмешиваться в процесс самого мероприятия, становясь
          сторонними наблюдателями, которые ловят естественные эмоции. Мы
          практически не делаем постановочные кадры. От этого, кстати, нам легко
          работать в команде. Успешный результат - это операторское искусство, а
          не вмешательство в процесс.
        </p>
        <div className="InfoBlock-Buttons">
          <Button
            title="Узнать подробнее"
            theme="solid"
            fill="gray"
            icon="long-arrow-right"
            iconPosition="right"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Видеосъемка',
                emailSubject: 'Запрос пакета услуг [Видео]',
              })
            }
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: '64px',
        }}
      >
        <div className="Photo Photo1">
          <div
            className="Photo_layered"
            style={{
              backgroundImage: `url(${v1})`,
              width: '280px',
              height: '420px',
            }}
          />
        </div>
        <div className="Photo Photo2" style={{ justifySelf: 'end' }}>
          <div
            className="Photo_bordered"
            style={{
              backgroundImage: `url(${v2})`,
              width: '320px',
              height: '480px',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Video
