import React, { useState, useRef, useEffect } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { cloneDeep, isEmpty } from 'lodash'
import DropdownBox from '../DropdownBox/DropdownBox'
import './AddressPicker.sass'
import { usePopUp } from '../../hooks/usePopUp'
import Spinner from '../Spinner/Spinner'
import Map from '../Map/Map'
import { handleSelect } from './functions/handleSelect'
import useModuleInit from '../../utils/newforms/changed/useModuleInit'
import useScript from '../../hooks/useScript'

const AddressPicker = ({
  accountId = null,
  formData,
  syncState = () => null,
  showErrors,
  withMap,
  disabled,
}) => {
  const inputRef = useRef()
  const ddbRef = useRef()
  const [apiWasLoaded, setApiWasLoaded] = useState(false)
  useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCpcrEqDx2ZkEC8Q7pElYxChoQSWRlP3uM&libraries=places',
    () => setApiWasLoaded(true)
  )
  const [address, setAddress] = useModuleInit({ formData, initialState: null })
  const [showPopUp] = usePopUp(inputRef, ddbRef)
  const [config, setConfig] = useState(null)

  const searchOptions = { types: ['geocode'] }

  useEffect(() => {
    syncState(address)
  }, [address])

  const handleChange = (inputString) => {
    const addressClone = cloneDeep(address)
    addressClone.inputAddress = inputString
    if (inputString === '') {
      addressClone.formattedAddress = ''
    }
    setAddress(addressClone)
  }

  return address && apiWasLoaded ? (
    <PlacesAutocomplete
      value={address.inputAddress}
      onChange={handleChange}
      onSelect={(selectedAddress) =>
        handleSelect({
          selectedAddress,
          address,
          setAddress,
          config,
          setConfig,
        })
      }
      shouldFetchSuggestions={address.inputAddress.length >= 3}
      searchOptions={searchOptions}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputClass = ['Field']

        if (address.inputAddress.trim() !== '') {
          inputClass.push('Field_with_value')
        }
        if (showErrors && address && address.formattedAddress === '') {
          inputClass.push('Field_status_invalid')
        }

        return (
          <div className="AddressPicker-Container">
            <div className="AddressPicker">
              <div className={inputClass.join(' ')}>
                <input
                  ref={inputRef}
                  {...getInputProps()}
                  disabled={disabled}
                />
                <span className="Field-Bar" />
                <label>Введите адрес</label>
                {showErrors && address && address.formattedAddress === '' && (
                  <div className="Field-Error">
                    Это поле не может быть пустым
                  </div>
                )}
              </div>
              {address.input}
              {showPopUp && (loading || !isEmpty(suggestions)) && (
                <DropdownBox ref={ddbRef}>
                  {loading && <Spinner type="popup" />}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'DropdownBox-Element DropdownBox-Element_theme_active'
                      : 'DropdownBox-Element'
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </DropdownBox>
              )}
            </div>
            {withMap && (
              <Map
                accountId={accountId}
                markers={[
                  ...(address.coordinates.lat !== 0.0
                    ? [
                        {
                          icon: 'building',
                          lat: address.coordinates.lat,
                          lng: address.coordinates.lng,
                        },
                      ]
                    : []),
                ]}
                address={address}
                setAddress={setAddress}
                useExternalConfig
                externalConfig={config}
                setExternalConfig={setConfig}
              />
            )}
          </div>
        )
      }}
    </PlacesAutocomplete>
  ) : (
    <Spinner type="module" />
  )
}
export default AddressPicker
