import { isString, isArray, isEmpty } from 'lodash'
import getOnlyNumbers from './getOnlyNumbers'

const getFormValues = ({
  form,
  omitSystemFields = false,
  withoutEmptyFields = false,
}) => {
  const formValues = {}

  Object.entries(form)
    .filter(([k, v]) => (omitSystemFields ? !v.render.isSystem : true))
    .map(([k, v]) => {
      if (v.render.isSystem) {
        formValues[k] = v.values
      } else if (v.isFieldset) {
        const values = v.values.map((fSet) => {
          const obj = {}
          fSet.forEach((f) => {
            if (withoutEmptyFields) {
              if (f.config.mask === 'number' && getOnlyNumbers(f.value)) {
                obj[f.fieldId] = getOnlyNumbers(f.value)
              } else {
                getValueByFieldType({
                  fieldType: f.fieldType,
                  value: f.value,
                  object: obj,
                  key: f.fieldId,
                })
              }
            } else {
              obj[f.fieldId] =
                f.config.mask === 'number' ? getOnlyNumbers(f.value) : f.value
            }
          })
          return obj
        })
        // console.log('🚀 ~ file: getFormValues.js ~ line 28 ~ values ~ values', values)
        if (v.render.byDefault) {
          if (withoutEmptyFields) {
            if (values.some((el) => !isEmpty(el) && true)) {
              formValues[k] = values
            }
          } else {
            formValues[k] = values
          }
        } else if (
          (isString(v.render.value) &&
            form[v.render.ifFieldId].value === v.render.value) ||
          (isArray(v.render.value) &&
            v.render.value.includes(form[v.render.ifFieldId].value))
        ) {
          if (withoutEmptyFields) {
            if (values.some((el) => !isEmpty(el) && true)) {
              formValues[k] = values
            }
          } else {
            formValues[k] = values
          }
        } else if (!withoutEmptyFields) {
          formValues[k] = []
        }
      } else if (v.render.helperData.useFieldsRange) {
        formValues[v.render.helperData.fieldId] = formValues[
          v.render.helperData.fieldId
        ]
          ? formValues[v.render.helperData.fieldId]
          : {}
        formValues[v.render.helperData.fieldId][
          `value${v.render.helperData.tag}`
        ] = v.config.mask === 'number' ? getOnlyNumbers(v.value) : v.value
      } else if (!withoutEmptyFields) {
        formValues[k] =
          v.config.mask === 'number' ? getOnlyNumbers(v.value) : v.value
      } else if (v.config.mask === 'number' && getOnlyNumbers(v.value)) {
        formValues[k] = getOnlyNumbers(v.value)
      } else {
        getValueByFieldType({
          fieldType: v.fieldType,
          value: v.value,
          object: formValues,
          key: k,
        })
      }
    })

  return formValues
}

const getValueByFieldType = ({ fieldType, value, object, key }) => {
  switch (fieldType) {
    case 'dateRange':
      if (value.from || value.to) {
        object[key] = value
      }
      break
    case 'phone':
      if (value.value) {
        object[key] = value
      }
      break
    case 'address':
      if (value.formattedAddress) {
        object[key] = value
      }
      break
    default:
      if (!isEmpty(value)) {
        object[key] = value
      }
      break
  }
}

export default getFormValues
