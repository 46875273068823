import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import React from 'react'

const SortableItem = SortableElement(({ value }) => value)

const SortableList = SortableContainer(({ items, className }) => {
  return (
    <div className={className}>
      {items.map((value, index) => (
        <SortableItem
          key={`sortableItem-${index}`}
          index={index}
          value={value}
        />
      ))}
    </div>
  )
})

export default SortableList
