import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Team.sass'
import Icon from '../../ui/Icon/Icon'
import Vera from './assets/v.jpg'
import Elena from './assets/e.jpg'
import Sima from './assets/s.jpg'
import Masha from './assets/m.jpg'
import TeamImg from './assets/team.jpg'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import Media from 'react-media'

export const teamArray = [
  {
    id: '6SAepbr8N0ZoNeNUKceMZaNGvlt1',
    photo: Vera,
    title: 'Вера Тарнавская',
    description: 'Видеограф',
    vk: 'https://vk.com/id4194283',
    inst: 'https://www.instagram.com/veratarni/',
  },
  {
    id: 'Y00cIUT5l2hf8XvOREqW5jhZNoW2',
    photo: Elena,
    title: 'Елена Нефёдова',
    description: 'Фотограф',
    vk: 'https://vk.com/haly_nife',
    inst: 'https://www.instagram.com/elenanefyodova.ph',
  },
  {
    id: 'HeEoslIiF4fdwoLCw1cpjZXPmAc2',
    photo: Sima,
    title: 'Серафима Столярова',
    description: 'Фотограф',
    vk: 'https://vk.com/id4472289',
    inst: 'https://www.instagram.com/serafimast/',
  },
  {
    id: 'yvP2ZnsvEQhoy52vfCiLYDbGbXA2',
    photo: Masha,
    title: 'Мария Старкова',
    description: 'Флорист, декоратор',
    vk: 'https://vk.com/mari_nef',
    inst: 'https://instagram.com/_mari_bloom_',
  },
]

function Team() {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <section id="team" className="Team DefaultSection">
      <SectionTitle title="Наша команда" />
      <div className="Team-Container">
        <div className="Team-Info">
          <img src={TeamImg} alt="" />
          <h5>One Day</h5>
          <p>
            One Day - коллектив творческих, любящих свое дело профессионалов,
            создающих видеофильмы, фотографии и хорошее настроение. Мы любим
            жизнь и пытаемся сохранить её удивительные моменты с помощью
            видеографии и фотографии. Будем иметь честь запечатлеть Вашу историю
            и историю Ваших близких и отобразить её в красивом,
            эмоционально-захватывающем виде.
          </p>
          <div className="TeamInfo-Buttons">
            <Button
              title="Узнать подробнее"
              theme="solid"
              fill="gray"
              icon="long-arrow-right"
              iconPosition="right"
              size={48}
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Узнать подробнее',
                  emailSubject: 'Обратная связь с сайта',
                })
              }
            />
          </div>
        </div>
        <div className="TeamTiles">
          <TeamTile {...teamArray[0]} />
          <TeamTile {...teamArray[3]} />
          <Media
            query="(min-width: 769px) and (max-width: 1700px)"
            render={() => (
              <>
                <TeamTile {...teamArray[2]} />
                <TeamTile {...teamArray[1]} />
              </>
            )}
          />
        </div>
        <div className="TeamTiles AnotherTiles">
          <TeamTile {...teamArray[2]} />
          <TeamTile {...teamArray[1]} />
        </div>
      </div>
    </section>
  )
}

function TeamTile({ photo, title, description, vk, inst, email }) {
  return (
    <div className="TeamTile" style={{ backgroundImage: `url(${photo})` }}>
      <div className="TeamTile-Info">
        <h5 className="Title">{title}</h5>
        <p className="Description">{description}</p>
        <div className="TeamTile-Social">
          <a href={inst} target="blank">
            <div className="Social-Block">
              <Icon name="instagram" weight="brand" />
            </div>
          </a>
          <a href={vk} target="blank">
            <div className="Social-Block">
              <Icon name="vk" weight="brand" />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Team
