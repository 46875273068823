import React from 'react'
import { HashLink } from 'react-router-hash-link'
import Icon from '../Icon/Icon'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'
import './ToTopButton.sass'

function ToTopButton() {
  const scrollOffset = -64
  return (
    <HashLink
      to={'#home'}
      smooth
      scroll={(el) => scrollWithOffset(el, scrollOffset)}
    >
      <div className="ToTopButton">
        <Icon name="angle-up" weight="regular" />
      </div>
    </HashLink>
  )
}

export default ToTopButton
