import { useEffect } from 'react'

const useScript = (url, cb) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)
    script.onload = () => {
      if (cb) cb()
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export default useScript
