import { cloneDeep } from 'lodash'

export const setTeaserPoster = ({ photoIndex, photos, setPhotos }) => {
  let photosClone = cloneDeep(photos)
  const photo = photosClone[photoIndex]

  if (photo.isTeaser) {
    photo.isTeaser = false
    photosClone[0].isTeaser = true
  } else {
    photosClone = photosClone.map((p, i) => {
      if (i === photoIndex) p.isTeaser = true
      else p.isTeaser = false
      return p
    })
  }

  setPhotos(photosClone)
}
