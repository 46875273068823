import { db } from '../../config/firebase'

export const updateDoc = ({ path, docId, data }) =>
  db
    .collection(path)
    .doc(docId)
    .update(data)
    .then(() => docId)
    .catch((e) => {
      console.log('🚀 ~ file: updateDoc.js ~ line 10 ~ updateDoc ~ e', e)
      return 'fail'
    })
