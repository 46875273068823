import React from 'react'
import './Spinner.sass'
import ButtonSpinner from './assets/button-spinner.svg'
import ButtonSpinnerGray from './assets/button-spinner-gray.svg'
import ModuleSpinner from './assets/module-spinner.svg'
import PopupSpinner from './assets/popup-spinner.svg'

const spinner = (props) => {
  let spinner = null
  switch (props.type) {
    case 'button':
      spinner = <img src={ButtonSpinner} alt="Loading..." />
      if (props.theme)
        spinner = <img src={ButtonSpinnerGray} alt="Loading..." />
      break
    case 'module':
      spinner = (
        <div className="ModuleSpinner-Container" style={props.styles}>
          <img src={ModuleSpinner} alt="Loading..." />
        </div>
      )
      break
    case 'popup':
      spinner = (
        <div className="PopupSpinner-Container" style={props.styles}>
          <img src={PopupSpinner} alt="Loading..." />
        </div>
      )
      break
    case 'layout':
      spinner = (
        <div className="LayoutSpinner-Container" style={props.styles}>
          <img src={ModuleSpinner} alt="Loading..." />
        </div>
      )
      break
    default:
      spinner = <img src={ButtonSpinner} alt="Loading..." />
      break
  }
  return spinner
}

export default spinner
