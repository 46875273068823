import { pick, omit, cloneDeep, isEmpty, isArray } from 'lodash'

const syncForm = ({ propIdentifier, form, setForm, pickProps = [], omitProps = [], newForm }) => {
  if (!form) return

  const formClone = cloneDeep(form)
  let formToSync = cloneDeep(newForm)

  if (!isEmpty(pickProps)) {
    formToSync = isArray(formToSync) ? formToSync.map((e) => pick(e, pickProps)) : pick(formToSync, pickProps)
  }

  if (!isEmpty(omitProps)) {
    formToSync = isArray(formToSync) ? formToSync.map((e) => omit(e, omitProps)) : omit(formToSync, omitProps)
  }

  if (isArray(propIdentifier))
    propIdentifier.forEach((prop, index) => {
      formClone[prop].values = formToSync[index]
    })
  else if (propIdentifier) {
    formClone[propIdentifier].values = formToSync
  }

  setForm(formClone)
}

export default syncForm
