import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { cloneDeep, find } from 'lodash'

export const handleSelect = ({ selectedAddress, address, setAddress, config = null, setConfig = null }) => {
  geocodeByAddress(selectedAddress)
    .then(([result]) => {
      let addressClone = cloneDeep(address)
      const { address_components, formatted_address, place_id } = result

      let country = find(address_components, (p) => p.types.includes('country')) || ''
      if (country) country = { name: country.long_name, short: country.short_name }

      let region = find(address_components, (p) => p.types.includes('administrative_area_level_1')) || ''
      if (region) region = { name: region.long_name, short: region.short_name }

      let localityName = find(address_components, (p) => p.types.includes('locality')) || ''
      if (localityName) localityName = localityName.long_name

      let district = find(address_components, (p) => p.types.includes('administrative_area_level_2')) || ''
      if (district) district = { name: district.long_name, short: district.short_name }

      let subLocalityName = find(address_components, (p) => p.types.includes('sublocality_level_1')) || ''
      if (subLocalityName) subLocalityName = { name: subLocalityName.long_name, short: subLocalityName.short_name }

      let route = find(address_components, (p) => p.types.includes('route')) || ''
      if (route) route = { name: route.long_name, short: route.short_name }

      let streetNumber = find(address_components, (p) => p.types.includes('street_number')) || ''
      if (streetNumber) streetNumber = streetNumber.long_name

      let postalCode = find(address_components, (p) => p.types.includes('postal_code')) || ''
      if (postalCode) postalCode = postalCode.long_name

      addressClone = {
        ...addressClone,
        country,
        region,
        localityName,
        district,
        subLocalityName,
        route,
        streetNumber,
        postalCode,
        formattedAddress: formatted_address,
        inputAddress: formatted_address,
        placeId: place_id,
      }

      getLatLng(result)
        .then(({ lat, lng }) => {
          addressClone = { ...addressClone, coordinates: { lat, lng } }
          setAddress(addressClone)

          if (config) {
            const configClone = cloneDeep(config)
            configClone.center = { lat, lng }
            setConfig(configClone)
          }
        })
        .catch((e) => console.log('AddressPicker -> getLatLng -> e', e))
    })
    .catch((e) => console.log('AddressPicker -> geocodeByAddress -> e', e))
}
