import React, { useContext } from 'react'
import './ProductTile.sass'
import { find, isEmpty } from 'lodash'
import Img from '../../ui/Img/Img'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import Icon from '../../ui/Icon/Icon'
import Carousel from '../../ui/Carousel/Carousel'
import Skeleton from '../../ui/Skeleton/Skeleton'
import ReactTooltip from 'react-tooltip'
import { productColors } from '../Boutique/ProductManager/ProductManager'

function ProductTile({
  isPublished,
  productId,
  category,
  title = 'Название товара',
  price = '2500',
  colors = ['gray'],
  sizes = ['xs', 's', 'm', 'l'],
  photos,
  video = {},
  onEdit,
  onDelete,
}) {
  const { user, setShowContactUs } = useContext(AuthContext)

  const slides = photos.map((p) => p.publicUrl)
  const avatar = find(photos, ['isAvatar', true])

  return (
    <div className="ProductTile">
      <ReactTooltip backgroundColor="#fff" textColor="#000" />
      <div className="ProductTile-Avatar">
        {avatar ? (
          <a href={avatar.publicUrl} data-fancybox={productId}>
            <Img src={avatar.publicUrl} />
          </a>
        ) : (
          <div className="ProductTile-NoAvatar">
            <Icon name="tshirt" />
          </div>
        )}

        <div className="PhotoTile-Markers">
          {user && (
            <>
              <div className="PhotoTile-Marker DefaultButton">
                <Icon name="pencil-alt" weight="solid" onClick={onEdit} />
              </div>
              <div className="PhotoTile-Marker DefaultButton">
                <Icon name="trash-alt" weight="solid" onClick={onDelete} />
              </div>
            </>
          )}
          {video.publicUrl && (
            <a
              className="PhotoTile-Marker DefaultButton"
              data-fancybox="gallery"
              href={video.publicUrl}
            >
              <Icon name="video" weight="solid" />
            </a>
          )}
        </div>

        {!isPublished && (
          <div className="PhotoTile-Marker NotPublished">Не опубликован</div>
        )}
      </div>
      <div key={`${category}-${productId}`} className="ProductTile-Thumbnails">
        {!isEmpty(slides) && (
          <Carousel
            element={`Product-${productId}`}
            options={{
              type: 'carousel',
              gap: 12,
              perView: 4,
              autoPlay: false,
              useDots: false,
              useArrows: true,
            }}
          >
            {slides.map((s) => (
              <div key={`thumbnail-${productId}-${s}`} className={productId}>
                <a href={s} data-fancybox={`${productId}-thumbnails`}>
                  <img src={s} />
                </a>
              </div>
            ))}
          </Carousel>
        )}
      </div>

      <div className="ProductTile-Info">
        <h5 className="Title">{title}</h5>
        <p className="Price">
          <Icon name="ruble-sign" weight="solid" />
          {price} <span>/cутки</span>
        </p>
        {video.publicUrl && (
          <p className="VideoReview">
            <span>
              <Icon name="video" weight="solid" />
            </span>
            <a
              className="VideoReview-Button"
              data-fancybox="gallery"
              href={video.publicUrl}
            >
              Видео-обзор модели
            </a>
          </p>
        )}
        <p className="Colors">
          <span className="LineTitle">Цвет:</span>
          {colors.map((c) => (
            <span
              className="ColorSquare"
              key={c}
              style={{ backgroundColor: colorsArray[c] }}
              data-tip={find(productColors, ['value', c]).label}
            />
          ))}
        </p>
        <p className="Sizes">
          <span className="LineTitle">Размеры:</span>
          {sizes.map((s) => (
            <span className="SizeTitle" key={s}>
              {s}
            </span>
          ))}
        </p>
        <p>{!isPublished && productId}</p>
      </div>
      <div className="ProductTile-Buttons">
        <Button
          title="Подробнее"
          theme="solid"
          fill="gray"
          icon="long-arrow-right"
          iconPosition="right"
          size={48}
          onClick={() =>
            setShowContactUs({
              show: true,
              title: 'Подробнее о прокате',
              emailSubject: 'Запрос информации о прокате',
              additionalContent: `
                Товар: ${title}<br/>
                ID: ${productId}
              `,
            })
          }
        />
      </div>
    </div>
  )
}

export function ProductTileSkeleton() {
  const skeletonStyles = {
    useTheme: true,
    color: '#232323',
    highlightColor: '#1C1C1C',
  }
  return (
    <div className="ProductTile">
      <Skeleton className="ProductTile-Avatar" {...skeletonStyles} />
      <div className="ProductTile-Thumbnails-Skeleton">
        <Skeleton width={54} height={54} {...skeletonStyles} />
        <Skeleton width={54} height={54} {...skeletonStyles} />
        <Skeleton width={54} height={54} {...skeletonStyles} />
        <Skeleton width={54} height={54} {...skeletonStyles} />
      </div>
      <div className="ProductTile-Info">
        <h5 className="Title">
          <Skeleton width={120} {...skeletonStyles} />
        </h5>
        <p className="Price">
          <Skeleton width={80} {...skeletonStyles} />
        </p>
        <p className="Colors Colors-Skeleton">
          <Skeleton width={60} {...skeletonStyles} />
          <Skeleton width={16} height={16} {...skeletonStyles} />
          <Skeleton width={16} height={16} {...skeletonStyles} />
          <Skeleton width={16} height={16} {...skeletonStyles} />
          <Skeleton width={16} height={16} {...skeletonStyles} />
        </p>
        <p className="Sizes">
          <Skeleton width={100} {...skeletonStyles} />
        </p>
        <p className="Details Details-Skeleton">
          <Skeleton width={120} {...skeletonStyles} />
        </p>
        <Skeleton height={40} {...skeletonStyles} />
      </div>
    </div>
  )
}

const colorsArray = {
  gray: '#D7D7D7',
  lightPink: '#F6D3FF',
  milk: '#FFE6C9',
  white: '#ffffff',
  green: '#8DA987',
  pink: '#F0C2DF',
}

export default ProductTile
