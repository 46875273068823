import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { find, isEmpty } from 'lodash'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Boutique.sass'
import { PortfolioNavLink } from '../Portfolio/Portfolio'
import { AuthContext } from '../../App'
import ProductTile, { ProductTileSkeleton } from '../ProductTile/ProductTile'
import Button from '../../ui/Button/Button'
import ProductManager from './ProductManager/ProductManager'
import { db } from '../../config/firebase'
import DeletePopUp from '../../ui/PopUp/DeletePopUp/DeletePopUp'
import onProductDelete from './ProductManager/deleteProduct'

function Boutique({ ...router }) {
  const { user, setShowContactUs } = useContext(AuthContext)
  const [showProductManager, setShowProductManager] = useState(null)
  const [deleteProduct, setDeleteProduct] = useState(null)
  const [blocks, setBlocks] = useState(productCategories)
  const [products, setProducts] = useState(null)
  const [displayedProducts, setDisplayedProducts] = useState(null)

  useEffect(() => {
    const listener = db
      .collection('products')
      .orderBy('pos')
      .onSnapshot((snap) => {
        const prdcts = []
        snap.forEach((p) => prdcts.push({ productId: p.id, ...p.data() }))
        setProducts(prdcts)
      })
    return () => listener()
  }, [])

  useEffect(() => {
    if (products) {
      setDisplayedProducts(
        products.filter(
          (p) =>
            p.category === find(blocks, ['isActive', true]).id &&
            (!user ? p.isPublished === true : true)
        )
      )
    }
  }, [products, blocks])

  useEffect(() => {
    if (router.location.hash) {
      if (find(blocks, ['id', router.location.hash])) {
        setBlocks(
          blocks.map((b) => {
            b.isActive = b.id === router.location.hash
            return b
          })
        )
      } else {
        setBlocks(
          blocks.map((b, i) => {
            b.isActive = i === 0
            return b
          })
        )
      }
    }
  }, [router.location.hash])

  return (
    <section
      id={find(blocks, ['isActive', true]).id.substring(1)}
      className="Boutique DefaultSection"
    >
      <SectionTitle title="Boutique" />
      <div className="Portfolio-NavContainer">
        <div className="Portfolio-Nav">
          {blocks.map((n) => (
            <PortfolioNavLink
              isActive={n.isActive}
              title={n.title}
              link={`/boutique${n.id}`}
              scrollOffset={n.scrollOffset}
            />
          ))}
        </div>
      </div>
      {showProductManager && (
        <ProductManager
          productId={showProductManager.productId}
          onClose={() => setShowProductManager(null)}
        />
      )}
      {deleteProduct && (
        <DeletePopUp
          title="Удаление товара"
          warningMessage={`Вы уверены, что хотите удалить товар "${deleteProduct.title}"?`}
          closePopUp={() => setDeleteProduct(null)}
          submitDelete={() =>
            onProductDelete({ productId: deleteProduct.productId })
          }
        />
      )}
      {user && (
        <div style={{ marginBottom: '64px' }}>
          <Button
            title="Добавить товар"
            theme="solid"
            fill="white"
            icon="plus"
            iconPosition="right"
            size={48}
            onClick={() => setShowProductManager({ productId: null })}
          />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Boutique-Catalog">
              {displayedProducts ? (
                <>
                  {displayedProducts.map((p) => (
                    <ProductTile
                      {...p}
                      onEdit={() =>
                        setShowProductManager({ productId: p.productId })
                      }
                      onDelete={() =>
                        setDeleteProduct({
                          title: p.title,
                          productId: p.productId,
                        })
                      }
                    />
                  ))}
                  {isEmpty(displayedProducts) && (
                    <p className="NoInfoRow">Нет товаров</p>
                  )}
                </>
              ) : (
                [
                  <ProductTileSkeleton />,
                  <ProductTileSkeleton />,
                  <ProductTileSkeleton />,
                  <ProductTileSkeleton />,
                ]
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const scrollOffset = -64
export const productCategories = [
  {
    title: 'Будуарные платья',
    id: '#boudoir-dresses',
    scrollOffset,
    isActive: true,
  },
  {
    title: 'Халаты',
    id: '#robe',
    scrollOffset,
    isActive: false,
  },
  {
    title: 'Свадебные платья',
    id: '#wedding-dresses',
    scrollOffset,
    isActive: false,
  },
]

export default withRouter(Boutique)
