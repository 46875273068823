import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './LocationPicker.sass'

function LangPicker() {
  return (
    <p className="LocationPicker">
      <Icon name="map-marker-alt" weight="solid" />
      <span>Мурманск</span>
    </p>
  )
}

export default LangPicker
