export const EMAIL = {
  pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  error: 'E-mail заполнен не корректно',
}

export const PHONE = {
  pattern: /^\+([38]){2}([\s])([\(])([0-9]){3}([\)])(\s)([0-9]){3}([\-])([0-9]){2}([\-])([0-9]){2}$/,
  error: 'Номер телефона указан не корректно',
}

export const PASSWORD = { error: 'Пароль должен содержать не менее 6 символов' }
