import React, { useState } from 'react'
import './PositionPopUp.sass'
import PopUp from '../../../ui/PopUp/PopUp'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'

function PositionPopUp({ posProp, items, onClose }) {
  console.log(
    '🚀 ~ file: PositionPopUp.jsx ~ line 9 ~ PositionPopUp ~ posProp',
    posProp
  )
  console.log(
    '🚀 ~ file: PositionPopUp.jsx ~ line 9 ~ PositionPopUp ~ items',
    items
  )
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(items, oldIndex, newIndex)
    newItems.map((item, index) =>
      updateDoc({
        path: 'projects',
        docId: item.id,
        data: { [posProp]: index },
      })
    )
  }

  return (
    <PopUp
      show
      close={onClose}
      title={'Изменить порядок проектов'}
      className="ContactUsPopUp"
    >
      <SortableList
        className="PositionPopUp-ElementGrid"
        axis="y"
        onSortEnd={onSortEnd}
        pressDelay={200}
        items={items.map((p, i) => (
          <ProjectSmallTile key={`projectSmallTile-${i}`} {...p} />
        ))}
      />
    </PopUp>
  )
}

function ProjectSmallTile({ title }) {
  return <div className="ProjectSmallTile">{title}</div>
}

export default PositionPopUp
