import React, { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Services.sass'
import { PortfolioNavLink } from '../Portfolio/Portfolio'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import VideoPhoto from './VideoPhoto'
import Video from './Video'
import Photo from './Photo'
import Floristics from './Floristics'
import { AuthContext } from '../../App'

function Services({ ...router }) {
  const scrollOffset = -64
  const { setShowContactUs } = useContext(AuthContext)

  const [blocks, setBlocks] = useState([
    {
      title: 'Видео + фото',
      id: '#service-video-and-photo',
      scrollOffset,
      block: <VideoPhoto setShowContactUs={setShowContactUs} />,
      isActive: true,
    },
    {
      title: 'Видео',
      id: '#service-video',
      scrollOffset,
      block: <Video setShowContactUs={setShowContactUs} />,
      isActive: false,
    },
    {
      title: 'Фото',
      id: '#service-photo',
      scrollOffset,
      block: <Photo setShowContactUs={setShowContactUs} />,
      isActive: false,
    },
    {
      title: 'Флористика и декор',
      id: '#service-floristics-and-decoration',
      scrollOffset,
      block: <Floristics setShowContactUs={setShowContactUs} />,
      isActive: false,
    },
  ])

  useEffect(() => {
    if (router.location.hash) {
      if (find(blocks, ['id', router.location.hash])) {
        setBlocks(
          blocks.map((b) => {
            b.isActive = b.id === router.location.hash
            return b
          })
        )
      } else {
        setBlocks(
          blocks.map((b, i) => {
            b.isActive = i === 0
            return b
          })
        )
      }
    }
  }, [router.location.hash])

  return (
    <section id="services" className="Services DefaultSection">
      <SectionTitle title="Услуги" />
      <div className="Portfolio-NavContainer">
        <div className="Portfolio-Nav">
          {blocks.map((n) => (
            <PortfolioNavLink
              isActive={n.isActive}
              title={n.title}
              link={`/${n.id}`}
              scrollOffset={n.scrollOffset}
            />
          ))}
        </div>
      </div>
      {find(blocks, ['isActive', true]).block}
    </section>
  )
}

export default withRouter(Services)
