import React, { useContext, useEffect, useState } from 'react'
import CheckBlock from '../../ui/CheckBlock/CheckBlock'
import './ProjectPage.sass'
import { teamArray } from '../../components/Team/Team'
import Button from '../../ui/Button/Button'
import Img from '../../ui/Img/Img'
import { withRouter } from 'react-router'
import Skeleton from '../../ui/Skeleton/Skeleton'
import { find, isEmpty } from 'lodash'
import { SkeletonTheme } from 'react-loading-skeleton'
import { categories } from './ManageProjectPage'
import { db } from '../../config/firebase'
import { AuthContext } from '../../App'
import ReactPlayer from 'react-player'
import ContactUsPopUp from '../../components/ContactUs/ContactUsPopUp'
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'

function ProjectPage({ ...router }) {
  const { user, setShowContactUs } = useContext(AuthContext)
  const projectId = router.match.params.id
  const [data, setData] = useState(null)
  console.log('🚀 ~ file: ProjectPage.jsx ~ line 23 ~ ProjectPage ~ data', data)
  useEffect(() => {
    db.collection('projects')
      .doc(projectId)
      .onSnapshot((snap) => {
        setData({ projectId: snap.id, ...snap.data() })
      })
  }, [projectId])
  return (
    <section id="home" className="ProjectPage">
      {data && (
        <Helmet>
          <title>{data.title} — Студия One Day</title>
        </Helmet>
      )}
      <div className="ProjectPage-Container">
        <div className="LeftSide">
          <SkeletonTheme color="#282828" highlightColor="#353535">
            <div className="Project-Details">
              <h3>{data ? data.title : <Skeleton width={220} />}</h3>
              <BlockData title={data ? 'Инфо' : <Skeleton width={80} />}>
                {data ? (
                  <>
                    <CheckBlock icon="user" text={data.title} />
                    <CheckBlock
                      icon="calendar-alt"
                      text={
                        <>
                          <span>Дата: </span>
                          {data.date}
                        </>
                      }
                    />
                    {data.category && (
                      <CheckBlock
                        icon="image"
                        text={
                          <>
                            <span>Категория: </span>
                            {find(categories, ['value', data.category]).label}
                          </>
                        }
                      />
                    )}
                  </>
                ) : (
                  <DataBlockSkeleton />
                )}
              </BlockData>
              <BlockData title={data ? 'Авторы' : <Skeleton width={80} />}>
                {data ? (
                  teamArray
                    .filter((t) => data.authors.includes(t.id))
                    .map((t) => <AuthorTile {...t} />)
                ) : (
                  <>
                    <AuthorTileSkeleton />
                    <AuthorTileSkeleton />
                  </>
                )}
              </BlockData>
              <div className="Buttons">
                {data ? (
                  <Button
                    title="Узнать подробнее"
                    theme="solid"
                    fill="gray"
                    icon="long-arrow-right"
                    iconPosition="right"
                    size={48}
                    onClick={() =>
                      setShowContactUs({
                        show: true,
                        title: 'Узнать подробнее',
                        emailSubject: 'Обратная связь с сайта',
                        additionalContent: `
                        Проект: ${data.title}<br/>
                        ID: ${data.projectId}
                      `,
                      })
                    }
                  />
                ) : (
                  <Skeleton width={200} height={48} />
                )}
              </div>
            </div>
          </SkeletonTheme>
        </div>
        <div className="Project-Content">
          {user && (
            <div>
              <Button
                title="Редактировать проект"
                theme="solid"
                fill="black"
                icon="pencil-alt"
                iconPosition="right"
                type="navlink"
                path={`/manage-project/${projectId}`}
                size={48}
              />
            </div>
          )}
          {data && (data.videoTeaser.publicUrl || data.videoClip.publicUrl) ? (
            <>
              <div className="Content-Title">Видео</div>
              <div className="Content">
                {data.videoTeaser.publicUrl && (
                  <div className="Content-Block">
                    <div className="EmptyField">
                      <ReactPlayer
                        url={data.videoTeaser.publicUrl}
                        width="100%"
                        height="100%"
                        controls
                        light={
                          find(data.photos, ['isTeaser', true]) &&
                          find(data.photos, ['isTeaser', true]).publicUrl
                        }
                      />
                    </div>
                    <p>Тизер</p>
                  </div>
                )}
                {data.videoClip.publicUrl && (
                  <div className="Content-Block">
                    <div className="EmptyField">
                      <ReactPlayer
                        url={data.videoClip.publicUrl}
                        width="100%"
                        height="100%"
                        controls
                        light={
                          find(data.photos, ['isClip', true]) &&
                          find(data.photos, ['isClip', true]).publicUrl
                        }
                      />
                    </div>
                    <p>Клип</p>
                  </div>
                )}
              </div>
            </>
          ) : data ? null : (
            <>
              <div className="Content-Title">
                <Skeleton width={180} height={32} />
              </div>
              <div className="Content">
                <div className="Content-Block">
                  <Skeleton height={360} />
                  <p>
                    <Skeleton width={160} />
                  </p>
                </div>
                <div className="Content-Block">
                  <Skeleton height={360} />
                  <p>
                    <Skeleton width={160} />
                  </p>
                </div>
              </div>
            </>
          )}
          {data &&
          data.photos.filter((p) => {
            if (p.isAvatar || p.isTeaser || p.isClip) return false
            return true
          }).length > 1 ? (
            <>
              <div className="Content-Title">Фото</div>
              <div className="Content-PhotoGrid">
                {data.photos.map((p) => (
                  <LazyLoad>
                    <a href={p.publicUrl} data-fancybox="images">
                      <Img
                        src={p.publicUrl}
                        alt={p.fileName}
                        className="PhotoTile-Skeleton"
                      />
                    </a>
                  </LazyLoad>
                ))}
              </div>
            </>
          ) : data ? null : (
            <>
              <div className="Content-Title">
                <Skeleton width={180} height={32} />
              </div>
              <div className="Content-PhotoGrid">
                <Skeleton height={220} />
                <Skeleton height={220} />
                <Skeleton height={220} />
                <Skeleton height={220} />
              </div>
            </>
          )}
        </div>
      </div>
      <ContactUsPopUp />
    </section>
  )
}

function BlockData({ title, children }) {
  return (
    <div className="BlockData">
      <h5>{title}</h5>
      <div className="BlockData-Content">{children}</div>
    </div>
  )
}

function AuthorTile({ photo, title, description }) {
  return (
    <div className="AuthorTile">
      <img src={photo} alt={title} />
      <div className="AuthorTile-Data">
        <p className="Title">{title}</p>
        <p className="Description">{description}</p>
      </div>
    </div>
  )
}
function AuthorTileSkeleton() {
  return (
    <div className="AuthorTile">
      <Skeleton width={48} height={48} style={{ marginRight: '12px' }} />
      <div className="AuthorTile-Data">
        <p className="Title">
          <Skeleton width={100} />
        </p>
        <p className="Description">
          <Skeleton width={50} />
        </p>
      </div>
    </div>
  )
}

function DataBlockSkeleton() {
  const content = []
  for (let index = 0; index < 4; index++) {
    content.push(
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton
          circle
          width={24}
          height={24}
          style={{ marginRight: '12px' }}
        />
        <Skeleton width={140} />
      </span>
    )
  }
  return content
}

export default withRouter(ProjectPage)
