import { useEffect, useState } from 'react'

const useScrollPosition = () => {
  const [pos, setPos] = useState(0)

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      setPos(document.scrollingElement.scrollTop)
    })
    return () => document.removeEventListener('scroll', (e) => null)
  }, [])

  return pos
}

export default useScrollPosition

// import { useLayoutEffect, useRef } from 'react'

// const isBrowser = typeof window !== `undefined`

// function useScrollPosition(effect, deps, element, useWindow, wait) {
//   const position = useRef(getScrollPosition({ useWindow }))

//   let throttleTimeout = null

//   const callBack = () => {
//     const currPos = getScrollPosition({ element, useWindow })
//     effect({ prevPos: position.current, currPos })
//     position.current = currPos
//     throttleTimeout = null
//   }

//   useLayoutEffect(() => {
//     const handleScroll = () => {
//       if (wait) {
//         if (throttleTimeout === null) {
//           throttleTimeout = setTimeout(callBack, wait)
//         }
//       } else {
//         callBack()
//       }
//     }

//     window.addEventListener('scroll', handleScroll)

//     return () => window.removeEventListener('scroll', handleScroll)
//   }, deps)
// }

// function getScrollPosition({ element, useWindow }) {
//   if (!isBrowser) return { x: 0, y: 0 }

//   const target = element ? element.current : document.body
//   const position = target.getBoundingClientRect()

//   return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top }
// }

// export default useScrollPosition
