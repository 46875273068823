const getFieldRenderObject = ({
  byDefault = true,
  className = '',
  isSystem = false,
  isChangableAfterSave = true,
  ifFieldId = '',
  value = '',
  dropOnFieldIdValueChanged = '',
  helperData = {},
} = {}) => ({
  byDefault,
  className,
  isSystem,
  isChangableAfterSave,
  ifFieldId,
  value,
  dropOnFieldIdValueChanged,
  helperData,
})

export default getFieldRenderObject
