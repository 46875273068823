import React from 'react'
import Button from '../../ui/Button/Button'
import CheckBlock from '../../ui/CheckBlock/CheckBlock'
import v1 from './assets/v1.jpg'
import v2 from './assets/v2.jpg'

function Floristics({ setShowContactUs }) {
  return (
    <div
      id="service-floristics-and-decoration"
      className="Services-Container"
      style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
    >
      <div className="InfoBlock">
        <h5>Флористика</h5>
        <p>
          Свадебный букет является важным дополнением к платью невесты, самым
          трогательным и нежным ее аксессуаром. Он несет в себе символичность и
          традицию, знаковость и множество поверий.
        </p>
        <p>
          Мы создаем главный букет девушки с ценностью, трепетом и любовью к
          каждому цветку, так же, как и другие свадебные аксессуары, дополняющие
          и отмечающие особую праздничную значимость свадебного дня.
        </p>
        <p>У нас вы можете заказать:</p>
        <CheckBlock text="Свадебный букет и бутоньерка жениха" />
        <CheckBlock text="Букетики, веночки, браслеты подружек невесты и бутоньерки друзей жениха" />
        <CheckBlock text="Дубль букет" />
        <CheckBlock text="Подарочные букеты родителям" />
        <CheckBlock text="Подарочные букеты для гостей с доставкой в ЗАГС" />
        <h5>Свадебное оформление</h5>
        <p>
          Свадебное оформление места проведения торжества создает настроение ,
          концепцию и стиль праздника, делает Ваши свадебные фото и видео на всю
          жизнь волшебными и сказочными, в них Вы бесконечно долго помните этот
          день и запах живых цветов, прикосновения к каждой красивой детали
          декора, сделанной со смыслом свадебной идеи.
        </p>
        <p>
          В зависимости от сценария Вашего события, мы можем предложить такие
          элементы свадебного флористического оформления и декора, как:
        </p>
        <CheckBlock text="Композиции из живых цветов на президиум" />
        <CheckBlock text="Гостевые столы" />
        <CheckBlock text="Создание и украшение фотозоны" />
        <CheckBlock text="Зоны выездной церемонии" />
        <CheckBlock text="Декор в стиле элегантного минимализма" />
        <CheckBlock text="Организация камерной свадьбы" />
        <p>
          Всем парам, заказавшим полный пакет услуг OneDay
          фото-видео-флористическое оформление : разработка единой концепции
          свадьбы, с учетом ваших пожеланий и возможностей, в виде
          презентованного мудборда торжества (палитра, идеи, стиль, материалы
          оформления и декора)
        </p>
        <div className="InfoBlock-Buttons">
          <Button
            title="Узнать подробнее"
            theme="solid"
            fill="gray"
            icon="long-arrow-right"
            iconPosition="right"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Флористика и декор',
                emailSubject: 'Запрос пакета услуг [Флористика и декор]',
              })
            }
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: '64px',
        }}
      >
        <div className="Photo Photo1">
          <div
            className="Photo_layered"
            style={{
              backgroundImage: `url(${v1})`,
              width: '320px',
              height: '480px',
            }}
          />
        </div>
        <div className="Photo Photo2" style={{ justifySelf: 'end' }}>
          <div
            className="Photo_bordered"
            style={{
              backgroundImage: `url(${v2})`,
              width: '320px',
              height: '480px',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Floristics
