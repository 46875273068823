import React, { useState, useEffect } from 'react'
import GoogleMap from 'google-map-react'
import MapMarker from './MapMarker/MapMarker'
import useWindowSize from '../../hooks/useWindowSize'
import { mapStyles } from './mapStyles'

function Map() {
  const { width } = useWindowSize()

  const config = {
    center: {
      lat: 59.931058,
      lng: 30.36091,
    },
    defaultCenter: {
      lat: 59.931058,
      lng: 30.36091,
    },
    defaultZoom: 1,
    draggable: true,
    options: { styles: mapStyles },
  }

  const [markers, setMarkers] = useState([
    { lat: 50.431759, lng: 30.517023 },
    { lat: 49.839683, lng: 24.029717 },
    { lat: 48.6208, lng: 22.287883 },
    { lat: 68.973311, lng: 33.085585 },
    { lat: 67.615087, lng: 33.661216 },
    { lat: 67.567105, lng: 33.391069 },
    { lat: 67.943134, lng: 32.891956 },
    { lat: 69.069037, lng: 33.408233 },
    { lat: 61.778162, lng: 34.364039 },
    { lat: 59.931058, lng: 30.36091 },
    { lat: 55.755826, lng: 37.6173 },
    { lat: 44.61665, lng: 33.525367 },
    { lat: 44.952117, lng: 34.102417 },
    { lat: 44.490946, lng: 34.153269 },
  ])

  return (
    <div className="Map">
      <GoogleMap
        bootstrapURLKeys={{
          key: 'AIzaSyA0BDTlJTQRjmEpEfGr9uvSnAOt-qF_rSs',
          language: 'ru',
        }}
        {...config}
      >
        {markers.map((m) => (
          <MapMarker {...m} />
        ))}
      </GoogleMap>
    </div>
  )
}

export default Map
