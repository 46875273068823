import React from 'react'
import { isEmpty } from 'lodash'
import uniqid from 'uniqid'
import SectionRender from './SectionRender'
import Skeleton from '../../../ui/Skeleton/Skeleton'

function FormRender({
  sections,
  sectionsSkeleton = [],
  form,
  setForm,
  errors,
  isFormSaved,
  closeForm,
}) {
  const key = uniqid('FRKey-')
  return !isEmpty(sections) && !isEmpty(form) ? (
    sections.map((section, i) => (
      <SectionRender
        key={`${section.title}-${i}`}
        {...section}
        form={form}
        setForm={setForm}
        errors={errors}
        isFormSaved={isFormSaved}
        closeForm={closeForm}
      />
    ))
  ) : (
    <FormRenderSkeleton key={key} sectionsSkeleton={sectionsSkeleton} />
  )
}

function FormRenderSkeleton({ sectionsSkeleton }) {
  return sectionsSkeleton.map((s) => {
    const fields = []
    const key = uniqid('Skeleton-')
    for (let index = 0; index < s; index++) {
      fields.push(<Skeleton height={28} />)
    }

    return (
      <>
        <div className="SceneContent-Header">
          <Skeleton width={80} />
        </div>
        {fields}
      </>
    )
  })
}

export default FormRender
