import { useRef } from 'react'

const useCallbacks = (reinit) => {
  const data = useRef({ callbacks: {}, handlers: {} })
  const { callbacks } = data.current
  const { handlers } = data.current

  // Generate new callbacks
  reinit(callbacks)

  // Generate new handlers if necessary
  for (const callback in callbacks) {
    if (!handlers[callback]) {
      handlers[callback] = (...args) => callbacks[callback](...args)
    }
  }

  // Return existing handlers
  return handlers
}

export default useCallbacks
