import React from 'react'
import Button from '../../ui/Button/Button'
import p1 from './assets/p1.jpg'
import p2 from './assets/p2.jpg'

function Photo({ setShowContactUs }) {
  return (
    <div id="service-photo" className="Services-Container Services-Photo">
      <div className="Photo Photo1" style={{ alignSelf: 'end' }}>
        <div
          className="Photo_bordered"
          style={{
            backgroundImage: `url(${p1})`,
            width: '310px',
            height: '465px',
          }}
        />
      </div>
      <div className="InfoBlock">
        <h5>Фотосъемка</h5>
        <p>
          Воспоминания - то, что останется в конечном итоге после свадьбы! Они
          останутся в памяти, в подарках и пожеланиях, и, конечно же, - в
          фотографиях! Именно они будут хранить теплые моменты того самого
          счастливого дня, напоминать о тех эмоциях и переживаниях, которые
          делают каждую свадьбу неповторимой и особенной.
        </p>
        <p>
          В любом деле хороший профессионал стоит своих денег. Ответственность
          свадебного фотографа очень велика, ведь от него зависят улыбки и
          воспоминания, а искренние и трогательные моменты специально не
          переиграешь. Мы очень любим своё дело, с трепетом относимся к каждой
          съемке.
        </p>
        <div className="InfoBlock-Buttons">
          <Button
            title="Узнать подробнее"
            theme="solid"
            fill="gray"
            icon="long-arrow-right"
            iconPosition="right"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Фотосъемка',
                emailSubject: 'Запрос пакета услуг [Фотосъемка]',
              })
            }
          />
        </div>
      </div>
      <div className="Photo Photo2" style={{ alignSelf: 'start' }}>
        <div
          className="Photo_layered"
          style={{
            backgroundImage: `url(${p2})`,
            width: '320px',
            height: '480px',
          }}
        />
      </div>
    </div>
  )
}

export default Photo
